import { throttle } from 'lodash';
import {
  EXPERIMENTS,
  SETTINGS_EVENT_ID,
  UPDATE_FULL_POST_STYLE,
} from '@wix/communities-blog-client-common';
import { openShowHidePanel } from '../../../components/Post Header/showHidePanel/showHideActions';
import type { EditorAppContext } from '../../../types/editor-app-context.type';
import {
  COMPONENT_DATA_CHANGED,
  SITE_WAS_SAVED,
  WIDGET_GFPP_CLICKED,
} from '../constants/events';
import concurrentEditing from './concurrent-editing';
import { setFullpostStyleParams } from './fullpost-layout-design-service';
import { savePostPageStyle } from './post-page-style';

export const addEventListeners = async (context: EditorAppContext) => {
  const { sdk } = context;

  await sdk.addEventListener(SITE_WAS_SAVED, async () => {
    try {
      await savePostPageStyle(context);
    } catch (e) {}
  });

  const throttledSetParams = throttle(setFullpostStyleParams, 1000);

  await sdk.addEventListener(COMPONENT_DATA_CHANGED, async (event) => {
    const eventType = (event?.detail?.previousData as any)?.content?.[
      SETTINGS_EVENT_ID
    ]?.payload;

    if (eventType === UPDATE_FULL_POST_STYLE) {
      await concurrentEditing.withApproval(async () => {
        throttledSetParams(context);
      });
    }
  });

  if (context.flowAPI.experiments.enabled(EXPERIMENTS.SPLIT_POST_PAGE)) {
    await sdk.addEventListener(WIDGET_GFPP_CLICKED, (event) => {
      const { id, componentRef } = event.detail;

      if (id === 'openShowHidePanel') {
        openShowHidePanel({
          editorSDK: sdk,
          widgetRef: componentRef,
          appToken: context.appToken,
        });
      }
    });
  }
};
